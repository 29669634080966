import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ParagraphBuilder from '../../components/ParagraphBuilder'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import Fade from 'react-reveal/Fade'

import Seo from '../../components/seo'
import SecondaryHeading from '../../components/SecondaryHeading'
import Section from '../../components/Section'
import Image from '../../components/image'

const Services = ({ langKey, location }) => {
  const { site } = useStaticQuery(
    graphql` query {
      site {
        siteMetadata {
          languages {
            defaultLangKey
            langs
          } 
          pagesData {
            applications {
              pageTitle {
                de
                en
                it
              }
              subtitle {
                de
                en
                it 
              }
              mainSection {
                paragraphs {
                  data {
                    de
                    en
                    it
                  }
                  title {
                    it
                    en
                    de
                  }
                  images
                  type
                }
                applicationTitle {
                  it
                  en
                  de
                }
                applications {
                  title {
                    it
                    en
                    de
                  }
                  images
                }
              }
            }
          }
        }
      }
    }
      `
  )
  const {
    pageTitle,
    mainSection,
    subtitle
  } = site.siteMetadata.pagesData.applications

  return (
    <>
      <Seo title={pageTitle[langKey]} urlPath={location.pathname} />
      <SecondaryHeading title={pageTitle[langKey]} />

      <Section>
        <div className='max-w-screen-lg m-auto px-6 md:px-0'>
          <h2 className='text-3xl underline font-bold mb-6'>{subtitle[langKey]}</h2>
          <ParagraphBuilder paragraphs={mainSection.paragraphs} langKey={langKey} nomargin />
        </div>
      </Section>
      <Section>
        <div className='max-w-screen-lg m-auto px-6 md:px-0'>
          <h2 className='text-3xl underline font-bold mb-10'>{mainSection.applicationTitle[langKey]}</h2>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 max-w-screen-lg m-auto px-6 md:px-0'>
          {
            mainSection.applications.map((el, i) => (
              <div key={`images_${i}`} className='mb-10'>
                <Fade left={i % 2 === 0} right={i % 2 !== 0}>
                  <div>
                    {
                      el.images.length > 1 ? (
                        <Carousel showThumbs={false} showStatus={false} showIndicators={false} autoPlay interval={4000} infiniteLoop>
                          {
                            el.images.map((im, j) => <Image key={`c_mi_${j}`} imgRef={im} />)
                          }
                        </Carousel>
                      ) : (
                        <Image imgRef={el.images[0]} />
                      )
                    }
                    <h3 className='mt-2 font-bold text-xl text-green-gefa'>{el.title[langKey]}</h3>
                  </div>
                </Fade>
              </div>
            ))
          }
        </div>
      </Section>
    </>
  )
}

export default Services
